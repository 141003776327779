import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { buildAdaptiveContentAdapter } from './adapter/adaptiveContent/adaptiveContent.adapter';
import { buildAdaptiveContentService } from './services/adaptiveContent/adaptiveContent.service';
import { ADAPTIVE_CONTENT_WIDGET_OPTIONS } from './constants';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  const { httpClient } = flowAPI;
  const adaptiveContentAdapter = buildAdaptiveContentAdapter(httpClient);
  const adaptiveContentService = buildAdaptiveContentService(
    adaptiveContentAdapter,
  );
  if (options.firstInstall) {
    const compRef = await editorSDK.application.appStudioWidgets.addWidget(
      '',
      ADAPTIVE_CONTENT_WIDGET_OPTIONS,
    );

    await editorSDK.application.appStudioWidgets.props.set('', {
      widgetRef: compRef,
      newProps: {
        compId: compRef.id,
      },
    });
  }

  await editorSDK.addEventListener('componentAddedToStage', async (event) => {
    const compRef = event.detail.compRef;
    await editorSDK.application.appStudioWidgets.props.set('', {
      widgetRef: compRef,
      newProps: {
        compId: compRef.id,
      },
    });
  });

  await editorSDK.addEventListener('siteWasPublished', async () => {
    return adaptiveContentService.triggerFunctionCleanup();
  });
};
// dummy commmit
