import { IEditorService } from '../../services/editor/types';
import { IAdaptiveContentService } from '../../services/adaptiveContent/types';
import { PanelResolveType } from '@wix/platform-editor-sdk';
import { ADAPTIVE_CONTENT_FUNCTION_TYPE_ID } from '../../constants';

const openBusinessManagerForFunction = async (
  editorService: IEditorService,
  functionExternalId: string,
  isFunctionExist: boolean,
): Promise<void> => {
  if (!isFunctionExist) {
    const stringifySpiConfigOverride = encodeURIComponent(
      JSON.stringify({ implementerConfigId: functionExternalId }),
    );

    const templateFunctionUrl = `/functions/manage/${ADAPTIVE_CONTENT_FUNCTION_TYPE_ID}?spiConfigOverride=${stringifySpiConfigOverride}`;
    await editorService.openBusinessManager(templateFunctionUrl);
  } else {
    const url = `/functions/edit/${functionExternalId}?referralInfo=adaptive-content-widget`;
    await editorService.openBusinessManager(url);
  }
};

function createNewFunction(
  adaptiveContentService: IAdaptiveContentService,
  componentId: string,
  editorService: IEditorService,
) {
  try {
    return adaptiveContentService.attachFunction(componentId, editorService);
  } catch {
    return editorService.showFailedToAttachFunctionError();
  }
}

export const getOrCreateFunctionExternalId = async (
  adaptiveContentService: IAdaptiveContentService,
  editorService: IEditorService,
  componentId: string,
): Promise<string> => {
  try {
    const functionExternalId =
      await adaptiveContentService.getFunctionExternalId(componentId);
    return functionExternalId.externalId;
  } catch (e) {
    const userResolvedAction = await editorService.showMissingFunctionError();
    const isCreateNewFunctionChosen =
      userResolvedAction === PanelResolveType.MAIN_ACTION;

    if (isCreateNewFunctionChosen) {
      return createNewFunction(
        adaptiveContentService,
        componentId,
        editorService,
      );
    }
    throw e;
  }
};

export const handleManageLogicClick = async (
  adaptiveContentService: IAdaptiveContentService,
  editorService: IEditorService,
) => {
  try {
    const component = await editorService.getFirstSelectedComponent();
    const functionExternalIdResult =
      await adaptiveContentService.getFunctionExternalId(component.id);
    return await openBusinessManagerForFunction(
      editorService,
      functionExternalIdResult.externalId,
      functionExternalIdResult.isExist,
    );
  } catch (error) {
    return editorService.showFailedToAttachFunctionError();
  }
};
